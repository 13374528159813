.default__height{
  position: relative;
  min-height: 100vh;
}
.btn__default, .pagination__buttonsss {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: inline-block;
  font-weight: 700;
  transition: all .4s ease-in-out;
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  line-height: 58px;
  padding: 0 42px;
  background-color: var(--primary-color);
  border-radius: 50px;
  margin-top: 17px;
}
.mt__7{margin-top: 7px;}
.mt__17{margin-top: 17px;}
.mt__27{margin-top: 27px;}
.mt__37{margin-top: 37px;}
.mt__47{margin-top: 47px;}
.mb__17{margin-bottom: 17px;}
.mb__27{margin-bottom: 27px;}
.mb__37{margin-bottom: 37px;}
.mb__77{margin-bottom: 77px;}
.required{color:rgb(255, 0, 0)}
.cols{
  display: flex;
  overflow: hidden;
}
.product__box{
  margin-bottom: 30px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  background: #fff;
}
.product__thumb {
  position: relative;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  min-height: 227px;
}
.product__thumb:before {
  content: "";
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  background: linear-gradient(to top,#000,transparent);
  opacity: 80%;
}
.product__thumb img.notsss {
  border-radius: 0;
  width: 100%;
  max-height: 227px;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
  height: 100%;
  box-shadow: none;
  max-width: 100%;
  border: none;
}
.product__box:hover .product__thumb img {
  transform: scale(1.05);
}
.product__thumb img{
  border-radius: 0;
  width: 100%;
  max-height: 227px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  height: 100%;
  box-shadow: none;
  max-width: 100%;
  border: none;
}
.product__type{
  position: absolute;
  top: 32px;
  left: 30px;
  z-index: 2;
  text-transform: capitalize;
}
.listing__arrowsss {
  width: 50px!important;
  height: 40px!important;
  left: 17%;
  position: absolute;
  top: -32px;
}
.product__type span {
  font-size: 14px;
  color: #fff;
  padding: 7px 15px;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  margin-right: 4px;
  white-space: nowrap;
  border: 1px solid #ea8241;
  font-weight: 500;
  background-color: #ea8241;
}
.product__price{
  position: absolute;
  bottom: 12px;
  left: 30px;
  z-index: 2;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
.product__price__range {
  display: inline-block;
  font-size: 18px;
}
.product__amount{
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.product__content{
  padding-bottom: 5px;
  border: 1px solid #d1e7e2;
  border-radius: 0 0 4px 4px;
}
.product__category {
  padding-top: 0;
  margin-top: -20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 2px;
}
.product__category p {
  margin-top: 0;
  position: relative;
  background: #fff;
  font-size: 18px;
  padding: 8px 12px;
  z-index: 10;
  line-height: 1;
  border-radius: 3px;
  color: #00c194;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0px;
}
.product__content__list{
  padding-left: 30px;
  padding-right: 30px;
}
.product__content__list h3{
  font-size: 22px!important;
  line-height: 32px!important;
  padding-top: 5px!important;
  margin-bottom: 8px!important;
  text-align: left!important;
  font-weight: 500!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.product__content__list p{
  color: #6e6b6b;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.product__content__list svg {
  vertical-align: middle;
  font-size: 16px;
}
.product__features{
  padding-bottom: 15px;
  border: none;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.product__features li {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}
.product__features li span{
  color: #00c194;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #edf9f6;
  display: inline-block;
  text-align: center;
  margin-right: 6px;
}
.product__features li svg{
  font-size: 18px;
}
.featured__section__homes {
  padding: 67px 0 67px 0;
  position: relative;
}
.fl__wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 27px;
}
.property__section__view {
  position: relative;
  padding: 67px 0px 67px;
  background-color: #ebf7f5;
  min-height: 100vh;
}
.so__groups__sticky {
  position: fixed;
  left: 0;
  z-index: 999;
  background: #fff;
  box-shadow: -2px 0 8px 0 #ddd;
  width: 60px;
  border-radius: 4px 0 0 4px;
  top: 40%;
}
.switch__checkbox {
  padding: 10px 7px;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  text-align: center;
}
.switch__checkbox .firsts {
  background-color: #ebf7f5;
  padding: 7px 7px 7px 7px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  box-shadow: 0 3px 3px rgb(0 0 0 / 13%);
  border-radius: 5px;
}
.switch__checkbox li{
  position: relative;
  margin-bottom: 4px;
}
.switch__checkbox li .seconds{
  display: none;
}
.switch__checkbox li:hover .seconds {
  position: absolute;
  left: 70px;
  right: 0;
  background-color: var(--primary-color);
  width: max-content;
  padding: 4px 10px 4px 10px;
  color: white;
  font-size: 18px;
  top: 6px;
  display: block;
}
.switch__checkbox .seconds::before {
  border-style: solid;
  border-width: 1em 0.75em 1em 0em;
  border-color: #00c19400 #00c194 #00c19400 #00c194;
  bottom: 0;
  content: "";
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
  position: absolute;
  left: -13px;
  z-index: 9999;
}
.switch__checkbox svg {
  font-size: 20px;
  color: var(--primary-color);
  vertical-align: middle;
}
.switch-checkbox:hover {
  background: #1266e3;
}
.switch-checkbox:hover span {
  opacity: 1;
  transition: all .2s ease-in-out 0s;
  visibility: visible;
  width: auto;
}
.switch-checkbox span.sec {
  color: #fff;
  background: #1266e3;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  opacity: 0;
  padding: 10px;
  position: absolute;
  right: 100%;
  text-align: center;
  text-transform: capitalize;
  top: 0;
  transition: all .2s ease-in-out 0s;
  visibility: hidden;
  white-space: nowrap;
  width: auto;
}
.rtcl__badge {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  padding: 6px 16px 6px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 17px;
  line-height: 1.4;
  position: relative;
}
.rtcl__type {
  background-color: #00c194;
}
.rtcl__featured {
  background-color: #ff9642;
}
.fl__wrap__two h3 {
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
}
.fl__wrap__two p{
  color: #878c9f;
  text-transform: capitalize;
}
.fl__wrap__two svg{
  color: #878c9f;
  font-size: 16px;
  margin-right: 8px;
}
.fl__wrap__two span svg {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -2px;
}
.fl__wrap__img {
  margin-top: 17px;
  position: relative;
  cursor: pointer;
}
.fl__wrap__img figure {
  position: relative;
  overflow: hidden;
  max-height: 467px;
  background-repeat: no-repeat;
  margin: 0;
  cursor: all-scroll;
}
.fl__wrap__img figure:hover img {
  opacity: 0;
}
.fl__wrap__img figure img{
  display: block;
  width: 100%;
  pointer-events: none;
}
.fl__wrap__img span {
  background-color: var(--primary-color);
  width: 37px;
  height: 37px;
  align-items: center;
  border-radius: 100%;
  box-sizing: content-box;
  color: #fff;
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  z-index: 9;
}
.fl__wrap__img span:hover {
  background-color: white;
  color: var(--primary-color);
}
.product__overview {
 position: relative;
 overflow: hidden;
 margin: 30px 0 30px;
}
.widget{
  border-radius: 4px;
  padding: 25px 30px 30px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  margin-bottom: -1px;
}
.heading__title {
  font-size: 20px;
  margin-bottom: 17px;
  color: #212121;
  line-height: 32px;
  font-weight: 600;
}
.amenities__list {
  display: flex;
  flex-wrap: wrap;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.amenities__list li:nth-child(4n+1), .amenities__list li:nth-child(4n+2) {
background-color: white;
}
.amenities__list li:last-child {
  flex-grow: 1;
}
.amenities__list li {
  width: 50%;
  position: relative;
  background: #EAF7F4;
  line-height: 40px;
  padding-left: 10px;
  margin: 3px 0;
  font-size: 17px;
}
.amenities__title {
  color: #212121;
  font-weight: 500;
  min-width: 120px;
  display: inline-block;
}
.amenities__value {
  color: #878c9f;
}
.Overview__list{
  display: flex;
  flex-wrap: wrap;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.Overview__list li{
  position: relative;
  margin-top: 10px;
  margin-bottom: 27px;
  width: 25%;
  font-size: 17px;
}
.amenities__icon{
  float: left;
  margin-right: 15px;
}
.amenities__icon {
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(232,233,241);
  border-radius: 4px;
  background-color: rgb(255,255,255);
  box-shadow: 0 4px 18px 0 rgb(188 192 202 / 26%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.amenities__icon svg{
  color: #00c194;
  font-size: 23px;
}
.amenities__content{
  overflow: hidden;
}
.amenities__content h3{
  color: #212121;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  margin-top: 2px;
}
.amenities__content__sss{
  color: #878c9f;
  line-height: 27px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
}
.product__sidebar{
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border: none;
  padding: 25px 30px 30px;
  margin-bottom: 30px;
}
.widget__contact h3{
  font-size: 22px;
  margin-bottom: 20px;
  color: #212121;
}
.widget__contact .form__control {
  padding-left: 17px;
  border: 1px solid rgba(0,0,0,.125);
  background-color: white;
  border-radius: 4px;
  color: #111;
  height: 50px;
}
.widget__contact .react-tel-input input {
  border: 1px solid rgba(0,0,0,.125)!important;
  background-color: white!important;
  border-radius: 4px!important;
  color: #111!important;
  height: 50px!important;
}
textarea.form__control {
  height: auto!important;
}
.css-1s2u09g-control{
 background: #f6f8fd!important;
 box-shadow: none!important;
 border: 1px solid #edf0f9!important;
 border-radius: 5px!important;
 padding-left: 17px;
 height: 60px!important;
 overflow: hidden;
 font-size: 18px!important;
 color: #212529!important;
 font-family: var(--font-family)!important;
}
.react-tel-input input{
 background: #f6f8fd!important;
 box-shadow: none!important;
 border: 1px solid #edf0f9!important;
 border-radius: 5px!important;
 padding-left: 17px;
 height: 60px!important;
 overflow: hidden;
 font-size: 18px!important;
 color: #212529!important;
 font-family: var(--font-family)!important;
 width: 100%!important;
}
.css-1s2u09g-control{
 font-size: 18px!important;
}
.css-319lph-ValueContainer{
 padding-left: 0px!important;
}
.css-26l3qy-menu{
 font-size: 18px!important;
}
.btn__submit {
  background-color: #00a376;
  border-radius: 3px;
  box-shadow: 0 7px 18px 0 rgb(0 193 148 / 32%);
  width: 100%;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #fff;
  padding: 0 30px;
  border: none;
  transition: all 0.3s ease-in-out;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}
.btn__submit:hover {
  background-color: #00c194;
}
.Show__pro {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.Show__pro p {
  padding: 10px 27px 10px 27px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 7px 18px 0 rgb(0 193 148 / 32%);
  flex-direction: row;
  margin-top: 17px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
}
.Show__pro p:hover{
  background-color: white;
  color: #00a376;
}
.contact__sellersss{
  padding: 0;
  list-style: none;
}
.contact__sellersss li {
  font-family: var(--font-family);
  font-size: 17px;
  margin-bottom: 10px;
  color: #70757a;
  font-weight: 500;
}
.contact__sellersss li svg {
  margin-right: 7px;
  font-size: 18px;
  vertical-align: middle;
  color: var(--primary-color);
}
.picedetails__views {
  position: relative;
  margin-top: 23px;
}
.priceamountsss {
  font-size: 37px;
  font-weight: 700;
  color: var(--primary-color);
  text-align: right;
}
.shares__views {
  padding: 0;
  list-style: none;
  display: inline-flex;
  position: relative;
  float: right;
}
.shares__views li{
  font-size: 18px;
  color: var(--primary-color);
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background: #fff;
  overflow: hidden;
  border: 1px solid #e0e5ee;
  border-right: none;
  display: block;
  cursor: pointer;
}
.proimgqqq {
  display: flex;
  margin-top: 17px;
  padding: 0;
  list-style: none;
  flex-direction: row;
  overflow-y: hidden;
}
.proimgqqq li {
  margin-right: 10px;
  width: 150px;
  height: 150px;
  cursor: pointer;
  flex: 0 0 auto;
}
.proimgqqq li img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.img__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99999;
  display: block;
}
.img__overlays {
  opacity: .5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.img__dialog {
  max-width: 992px;
  margin: 30px auto;
  min-height: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  z-index: 999;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.img__dialog img{
  background-color: white;
  padding: 10px;
}
.img__screens{
  position: absolute;
  right: 17px;
  top: 10px;
  font-size: 37px;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
  overflow: hidden;
  border-right: none;
  display: block;
  cursor: pointer;
}
.img__close:hover{
  color: var(--primary-color);
}
.img__dialog__thumpsss {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 17px;
  width: auto;
  z-index: 999;
  justify-content: center;
  flex-direction: column;
}
.img__dialog__thumpsss .proimgqqq {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 87vh;
}
.img__dialog__thumpsss .proimgqqq li {
  margin-right: 0;
  width: 127px;
  height: 87px;
  cursor: pointer;
  margin-bottom: 7px;
  background-color: white;
  padding: 2px;
}
.react__player__secsss {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 17px;
}
.react__player {
  position: absolute;
  top: 0;
  left: 0;
}
.Video__innerpage2 {
  position: relative;
  margin-bottom: 37px;
}
.Video__innerpage2 iframe {
  width: 100%;
  height: 437px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0);
  border-radius: 17px;
  border: 7px solid #fff;
}
.featured__section__homes h3 {
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 37px;
}
.product__box__featured {
  padding: 20px 0 20px 20px;
  display: flex;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  overflow: hidden;
  width: 96%;
}
.product__thumb__featured {
  min-width: 220px;
  max-width: 220px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 157px;
}
.product__content__featured {
  padding-bottom: 5px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0 0 4px 4px;
}
.cc7{
  color: #04aa83;
}
.featured__section__homes .product__type {
  top: 7px;
  left: 7px;
}
.featured__section__homes .product__type span {
  padding: 7px 10px;
}
p.featured__category {
  margin-bottom: 0;
  font-size: 16px;
}
.featured__section__homes .product__content__list {
  padding-left: 20px;
  padding-right: 20px;
}
.featured__locations {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.homes__section__homes .product__content__list p {
  text-transform: capitalize;
}
.seller__section__homes .product__content__list p {
  text-transform: capitalize;
}
.rent__section__homes .product__rent__list p {
  text-transform: capitalize;
}
.rent__section__homes .product__content__list p{
  text-transform: capitalize;
}
.span.agent__locsss{
  text-transform: capitalize;
}
.featured__locations svg {
  vertical-align: unset;
  font-size: 14px;
}
.featured__section__homes .product__content__list h3 {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 2px;
  padding-top: 0;
}
.featured__section__homes li svg {
  font-size: 16px;
  vertical-align: unset;
  margin-right: 18px;
}
.featured__section__homes li span {
  width: 17px;
  height: 17px;
  background-color: #edf9f600;
  margin-right: 7px;
}
.featured__amount p {
  margin-bottom: 0;
  color: #00c194;
  font-weight: 600;
  font-size: 18px;
}
.featured__section__homes .product__features{
  margin-bottom: 0;
}
.featured__section__homes .product__features li {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #505350;
}
.featured__section__homes img {
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
.featured__section__homes h2 {
  font-size: 16px;
  text-align: center;
  color: #ea8241;
}
.cc8{
  color: #ea8241;
}
.soldsss{
  border: 1px solid #ff1302!important;
  background-color: #ff1100!important;
}
.seller__section__homes .cols, .homes__section__homes .cols{
  padding-left: 17px;
  padding-right: 17px;
}
.homes__section__homes {
  padding: 67px 0 67px 0;
  position: relative;
  background-color: #F1F8F6;
}
.homes__section__homes h2 {
  font-size: 16px;
  text-align: center;
  color: #ea8241;
}
.homes__section__homes h3 {
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 37px;
}
@media (max-width: 767px) { 
  .homes__section__homes h3 {
    font-size: 24px;
  }
}
.page__sectionsss{
  padding: 37px 0 67px 0;
  position: relative;
  background-color: #ebf7f5;
}
.page__sectionsss .product__features {
  justify-content: flex-start;
  margin-bottom: 7px;
}
.page__sectionsss  .featured__amount {
  border-top: 1px solid #e1e1e1;
  padding-top: 4px;
}
.page__sectionsss .product__features li {
  margin-right: 10px;
  font-weight: 500;
  color: #525452;
}
.page__sectionsss .featured__amount p {
  font-size: 30px;
}
.page__sectionsss  .product__type {
  left: 15px;
}
.product__thumb__psgsss {
  min-width: 250px;
  max-width: 250px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 200px;
  background-color: #f5f6fb;
}
.product__thumb__psgsss img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  margin: 0 auto;
  display: block;
}
.product__box__psgsss {
  padding: 20px 0 20px 20px;
  display: flex;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  overflow: hidden;
}
.product__content__psgsss {
  padding-bottom: 5px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0 0 4px 4px;
}
ul.sqftcenfmsss {
  position: absolute;
  right: -85px;
  padding: 7px;
  background-color: #ebf7f5;
  border: 1px solid #00c194;
  border-radius: 7px;
}
ul.sqftcenfmsss li{
  cursor: pointer;
}
.sqffsss {
  color: #00c194;
  margin-top: 4px;
  cursor: pointer;
}
.breadcrumbs {
  position: relative;
  z-index: 1;
  color: #878c9f;
  padding: 37px 0 0 0;
  background-color: #ebf7f5;
}
.breadcrumbs a{
  font-size: 18px;
  font-weight: 500;
}
.breadcrumbs span {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
}
.rtcl__ordering{
  font-weight: 500;
  color: #878c9f;
  background-color: #fff;
  position: relative;
  right: 0;
  padding-right: 38px;
  background-position: calc(100% - 20px) 19px,calc(100% - 15px) 19px,calc(100% - 0.5em) 0.5em;
  height: 44px;
  border: 1px solid #e1e5ee;
  border-radius: 3px;
  width: 131px;
  padding-left: 10px;
  font-size: 18px;
  transform: translateY(2px);
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(
45deg
,transparent 50%,#646464 50%),linear-gradient(
135deg
,#646464 50%,transparent 50%),radial-gradient(transparent 70%,transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 4px),calc(100% - 15px) calc(1em + 4px),calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px,5px 5px,1.5em 1.5em;
  background-repeat: no-repeat;
  cursor: pointer;
}
.sortsss {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.sortsss label {
  display: inline-block;
  font-size: 18px;
  color: black;
  font-weight: 600;
  margin-right: 7px;
}
.layoutsss{
  border: 1px solid #e1e5ee;
  border-radius: 3px;
  display: flex;
  margin-left: 15px;
  background-color: #fff;
  cursor: pointer;
}
.layoutsss span{
  width: 46px;
  height: 42px;
  color: #b4b4b4;
  line-height: 42px;
  text-align: center;
  border-right: 1px solid #e1e5ee;
}
.sortsss2sss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
}
.sortsss2sss p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.layoutsss__actives svg{
  color: var(--primary-color);
}
.product__box__psgsssgrid{
  display: flex;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 47px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
}
.product__thumb__psgsssgrid {
  min-width: 100%;
  max-width: 100%;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  height: 272px;
  background-color: #f5f6fb;
}
.product__content__psgsssgrid {
  padding-bottom: 14px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 0 0 4px 4px;
}
.product__content__listgrid {
  padding-left: 30px;
  padding-right: 30px;
}
.product__content__listgrid h3 {
  font-size: 22px;
  line-height: 32px;
  padding-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.product__content__listgrid p {
  color: #929292;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.product__content__listgrid svg {
  vertical-align: middle;
  font-size: 16px;
}
.product__content__listgrid .product__features {
  height: 37px;
  overflow: hidden;
}
.product__content__listgrid .product__price p {
  margin-bottom: 0rem;
  font-size: 27px;
}
.page__sectionsss .product__box__psgsssgrid .product__type {
  top: 34px;
  left: 27px;
}
.col__left__20{
  padding-left: 20px;
}
.col__left__15{
  padding-left: 20px;
}
.video__section__homes {
  background-image: var(--video-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 86.7216px;
  position: relative;
  min-height: 590px;
  display: flex;
  align-items: center;
}
.video__section__contents {
  padding: 40px 50px 45px 50px;
  background-color: white;
  position: relative;
}
.top__sub__title {
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ea8241;
  font-weight: 700;
  letter-spacing: 1.1px;
}
.video__section__contents h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
}
.video__icon__inner{
  display: inline-flex;
  align-items: center;
  margin-top: 17px;
}
.video__icon__inner a {
  display: flex;
  align-items: center;
}
.video__icon__inner a p {
  margin-left: 20px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}
.video__icon__inner .video__icon__innersss{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #fff;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.triangle {
  z-index: 3;
  width: 9px;
  height: 12px;
  transition: .4s;
  background: #fff;
  clip-path: polygon(100% 50%,0 3%,0 100%);
  margin-left: 2px;
}
.ripple__effect {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  border-radius: 50%;
  animation-duration: 2s;
}
.ripple__effect::before {
  animation: toTopAnimation 2s linear infinite;
}
.ripple__effect::after {
  animation: toTopAnimation 2s 1s linear infinite;
}
.ripple__effect::before, .ripple__effect::after {
  border-color: var(--primary-color);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  left: 0;
  top: 0;
  transition: 0.4s;
}
@-webkit-keyframes toTopAnimation {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	50% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4)
	}
	100% {
		-webkit-transform: scale(1.8);
		transform: scale(1.8);
		opacity: 0
	}
}

@keyframes toTopAnimation {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	50% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4)
	}
	100% {
		-webkit-transform: scale(1.8);
		transform: scale(1.8);
		opacity: 0
	}
}
.video__section__text {
  font-size: 82px;
  color: #FFF;
  -webkit-text-stroke-color: #FFF;
  display: inline-block;
  font-family: 'Great Vibes', cursive;
  line-height: 114px;
  pointer-events: none;
  margin-top: 37px;
  margin-left: 30px;
  text-align: center;
  text-shadow: 1px 2px 2px #00634c;
}
.Agent__section__homes{
  padding: 60px 0 60px 0;
  position: relative;
  background-image: url(./img/banner12.png);
  background-color: #F1F8F6;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
}
.cc6 {
  background-color: #ea8241!important;
}
.agent__homes__left p{
  max-width: 900px;
  text-align: center;
}
.new__section__homes2{
  margin: 60px 0px 110px 0px;
  padding: 40px 0px 60px 0px;
  position: relative;
  overflow: hidden;
}
.new__section__homes2s {
  padding: 47px 47px 47px 47px;
  position: relative;
  background-color: #fff4e6;
  background: linear-gradient(270deg,#ecfdf5 22%,#fff 47%,#fff 60%,#ecfdf5b8);
  box-shadow: 2px 0px 0px 0px #cbe9dc;
  border-radius: 17px;
}
.new__section__homes2 h2 {
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 27px;
}
.new__section__homes2 span {
  background: linear-gradient(to bottom right,#ffaf7f 0%,#ff721d 50%,#d86219 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: 36px; */
}
.ccone {
  position: absolute;
  right: -70px;
  top: 0;
  width: 47%;
  z-index: -7;
}
.cc3 {
  position: absolute;
  left: 0px;
  top: 0;
  width: 60%;
}
.cc2{
  margin-top: 4px;
}
@media (max-width: 767px) { 
  .new__section__homes2s {
    padding: 17px 17px 17px 17px;
  }
  .new__section__homes2 span {
    font-size: 30px!important;
  }
  
}
@media(min-width:992px) and (max-width:1199px){ 
  .agent__homes__left {
    max-width: 817px;
    padding-right: 0;
  }
  .Agent__section__homes h3 {
    font-size: 26px!important;
    margin-bottom: 7px!important;
  }
  .agent__homes__right {
    margin-top: 10px!important;
  }
  .Agent__section__homes {
    padding: 47px 0 47px 0!important;
  }
}
@media(min-width:1200px) and (max-width:1400px){ 
  .agent__homes__left {
    max-width: 867px;
    padding-right: 27px;
}
}

.new__section__homes2s ul {
  padding-left: 0px;
}
.new__section__homes2 ul li {
  text-align: left;
  list-style: none;
  font-size: 20px;
  margin-bottom: 10px;
}
.new__section__homes2 .btn__default__agentsss{
  background: linear-gradient(to bottom right,#ffaf7f 0%,#ff721d 50%,#d86219 100%);
  display: block;
}

.new__section__homes{
  padding: 60px 0px 10px 0px;
  position: relative;
  overflow: hidden;
}
.new__section__homes h2 {
  z-index: 0;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 17px;
}
.new__section__homes span {
  color: #00c194;
  font-weight: 700;
}
.new__section__homes h3 {
  font-size: 22px;
  margin-top: 17px;
  font-weight: 500;
}
.agent__homes__left h2{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 17px;
  text-align: center;
}
.new__section__homes h4 {
  font-size: 24px;
  margin-top: 27px;
  font-weight: 700;
}
.product__box__rent_swqs {
  padding: 20px 20px 20px 20px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  transition: all 0.3s ease-in-out;
  margin-top: 17px;
}
.product__box__rent_swqs b{
  color: #ea8241;
}
.product__box__rent_mns {
  padding: 20px 20px 20px 20px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  transition: all 0.3s ease-in-out;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 17px;
}
.product__box__rent_mns h3 {
  font-weight: 600;
  margin-bottom: 17px;
  margin-top: 0;
}
.product__box__rent_mns span.mqxasss {
  color: #ea8241;
  font-weight: 600;
}
.aggsss__left {
  position: absolute;
  width: 197px;
  left: 107px;
  top: 14px;
}
.aggsss__right{
  position: absolute;
  width: 267px;
  right: 0;
  bottom: 0;
}
.agent__homes__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}
.agent__homes__right button {
  margin-top: 10px;
}
.Agent__section__homes h3 {
  z-index: 0;
  font-size: 37px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 17px;
}
.Agent__section__homes p{
  z-index: 0;
  margin-bottom: 0;
}
.agent__homes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
.agent__homes .btn__default__agentsss{
  padding: 4px 30px 4px 30px;
  margin-top: 17px;
}
.gold__innerpagetwo {
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  overflow: hidden;
}
.Platinum__innerpage {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 17px;
}
.gold__innerpagetwo h2 {
  font-size: 22px;
  line-height: 32px;
  padding-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
  text-align: center;
}
.property__section__view  .gold__innerpagetwo h2 {
  font-size: 27px;
  padding-top: 17px;
  text-align: left;
}
.gold__innerpagetwo h2:hover a{
  color: var(--primary-color);
}
.agent__section__logins{
  padding: 67px 0 67px 0;
  position: relative;
  background-color: #F1F8F6;
}
.agent__section__logins form {
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border-radius: 6px;
  border: none;
  padding: 37px 47px 37px;
  background-color: #fff;
  margin: 0 0 30px;
}
.agent__section__logins label {
  font-size: 18px;
  font-weight: 600;
  color: #455892;
  margin-bottom: 10px;
  display: inline-block;
  font-family: var(--font-family);
}
.agent__section__logins h2 {
  font-size: 30px;
  text-align: left;
  margin: 10px 0 37px;
  font-weight: 600;
  line-height: 26px;
}
.agent__section__logins p.forgggsss2 {
  text-align: left;
  cursor: pointer;
}
.agent__section__logins .ql-container.ql-snow {
  border: 1px solid #d3d5d8;
  background-color: #f6f8fd;
}
.agents__sec .product__content__list .product__desc {
  max-height: 97px;
  overflow: hidden;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.agents__sec .product__content__list p {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 3px;
  color: #788593!important;
  font-size: 17px!important;
  line-height: 27px!important;
  margin-bottom: 17px;
  -webkit-line-clamp: 2!important;
  word-break: break-all;
}
.link__btns {
  font-weight: 600;
  color: var(--primary-color);
  height: 40px;
  padding: 0 22px;
  border-radius: 4px;
  line-height: 36px;
  font-size: 17px;
  background: var(#dceeea,#dfefeb);
  outline: none;
  border: 0;
}
.link__btns:hover {
  color: white;
  background: var(--primary-color);
}
.agentss__headings__sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.agent__wrap {
  position: relative;
  margin-bottom: 50px;
}
.agent__wrap__img {
  background-color: #E1E1E1;
  border-radius: 4px;
  overflow: hidden;
  height: 280px;
}
.agent__wrap__img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.agent__wrap__img:after {
  content: "";
  background: rgba(0,0,0,.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
}
.agent__wrap__content {
  position: absolute;
  top: 0;
  left: 25px;
  display: flex;
  height: 100%;
  width: 100%;
}
.agent__logo {
  flex: 1;
  margin-right: 25px;
  max-width: 250px;
  align-self: center;
  position: relative;
}
.agent__logo img {
  border-radius: 4px;
  background: #f4f6fb;
  max-width: 100%;
  height: auto;
}
.agent__area{
  flex: 1 0 0%;
  align-self: center;
  padding-right: 15px;
}
.agent__area h2 {
  color: #fff;
  font-size: 27px;
  line-height: 34px;
}
.agent__area p{
  color: white;
}
.tab__content {
  padding: 22px 30px 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}
.tab__content__headingsss{
  font-size: 20px;
  color: #212121;
  font-weight: 700;
  margin-bottom: 10px;
}
.tab__widget {
  border-radius: 4px;
  padding: 25px 30px 30px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}
.tab__widget__headingsss{
  font-size: 18px;
  color: #212121;
  margin-bottom: 23px;
  font-weight: 600;
}
ul.tab__widget__list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.tab__widget__list li{
  color: #70778b;
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;
  padding-bottom: 12px;
  font-size: 18px;
}
.tab__widget__list svg {
  width: 26px;
  display: inline-block;
  color: #aab4cf;
  font-size: 18px;
  vertical-align: middle;
  margin-top: -2px;
}
.tab__widget__list strong {
  color: #07c196;
  font-weight: 600;
}
.link__btnstwo {
  font-weight: 600;
  color: white;
  height: 42px;
  padding: 0 22px;
  border-radius: 4px;
  line-height: 37px;
  font-size: 17px;
  background: var(--primary-color);
  outline: none;
  border: 0;
}
.link__btnstwo:hover {
  color: var(--primary-color);
  background: #dceeea;
}
.tab__content__shows{
  padding: 0;
  border: none;
  margin-bottom: 20px;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.tab__content__shows li {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  padding: 0 30px;
  height: 60px;
  border: 1px solid #eee;
  position: relative;
  display: block;
  line-height: 58px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}
.tab__content__shows li:hover{
  background-color: var(--primary-color);
  color:white;
}
.tab__active__shows {
  background-color: var(--primary-color)!important;
  color:white!important;
}
.agent__meta{
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: #fff;
  border-radius: 3px;
  padding: 1px 10px 1px 17px;
  line-height: 25px;
  overflow: hidden;
  transition: 0.4s;
}
.agent__meta:before{
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--primary-color);
  left: 6px;
  top: 10px;
  position: absolute;
}
.agent__wrap:hover .agent__meta{
  color: #fff;
  background-color: #00a376;
}
span.ads__count {
  font-size: 16px;
}
.agent__area svg {
  color: #00c194;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 7px;
}
.list__disablesss{
  display: none;
}
span.agent__locsss {
  font-size: 18px;
}
span.agent__locsss svg {
  color: #00c194;
}
.featured__section__homes .product__features {
  height: 30px;
  overflow: hidden;
}
.homes__section__homes  .product__features,  .seller__section__homes .product__features {
  height: 37px;
  overflow: hidden;
}
.product__amount p{
  font-size: 24px;
}
.seller__section__homes {
  padding: 67px 0 67px 0;
  position: relative;
}
.seller__section__homes h2 {
  font-size: 16px;
  text-align: center;
  color: #00c194;
}
.seller__section__homes h3 {
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 37px;
}
.rent__section__homes {
  padding: 67px 0 67px 0;
  position: relative;
}
.rent__section__homes h2 {
  font-size: 16px;
  text-align: left;
  color: #00c194;
}
.rent__section__homes h3 {
  font-size: 34px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 37px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.rent__section__homes a {
  margin: unset;
}
.Highlight__section__homes .featured__amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 27px;
}
.Highlight__section__homes .featured__amount a{
  margin: unset;
}
.product__box__rent{
  padding: 20px 0 20px 20px;
  display: flex;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 22px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  margin-left: 17px;
  margin-right: 17px;
}
.product__thumb__rent {
  position: relative;
}
.product__thumb__rent img {
  max-width: 220px;
  height: 167px;
  border-radius: 6px;
  object-fit: cover;
  object-position: center top;
}
.product__thumb__rent .product__type {
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 2;
  text-transform: capitalize;
}
.product__box__rent a {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.product__content__rent {
  padding-bottom: 5px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product__content__rent .product__content__list {
  padding-left: 17px;
  padding-right: 17px;
}
.product__price__Rent p{
  color: var(--primary-color);
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}
.product__box__renttwo {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.thumb__wrapper {
  padding: 30px 30px 17px;
  background: #fff;
  margin-bottom: 10px;
}
.thumb__wrapper .product__thumb__rent img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  object-fit: cover;
  object-position: center top;
  width: 100%;
  max-height: 417px;
}
.product__rent__list h3{
  font-size: 27px;
  line-height: 37px;
  padding-top: 5px;
  margin-bottom: 17px;
  font-weight: 500;
  margin-top: 17px;
}
.product__rent__list p {
  color: #666363;
}
.product__rent__list svg {
  vertical-align: middle;
  font-size: 16px;
}
.product__rent__list .product__features {
  margin-bottom: 12px;
  margin-top: 23px;
  border-bottom: 1px solid lavender;
  padding-bottom: 17px;
}
.product__price__Renttwo p {
  color: var(--primary-color)!important;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 0;
}
.product__box__rent .product__features li span {
  width: 27px;
  height: 27px;
}
.product__box__rent .product__features li svg {
  font-size: 16px;
}
.product__box__rent .product__features li {
  font-size: 14px;
  font-weight: 500;
}
.product__rent__list h3:hover{
  color: var(--primary-color);
  text-decoration: underline;
}
.product__content__rent h3:hover{
  color: var(--primary-color);
  text-decoration: underline;
}
.product__box__rent ul.sqftcenfmsss {
  right: -7px;
  padding: 7px;
  bottom: 27px;
  display: flex;
  width: 147px;
  flex-direction: row;
  justify-content: space-between;
}
.product__rent__list ul.sqftcenfmsss {
  right: 7px;
  padding: 7px;
  bottom: 27px;
  display: flex;
  width: 167px;
  flex-direction: row;
  justify-content: space-between;
}
.amenities__list p {
  margin-bottom: 0;
  display: inline-block;
}
.ag__logsss .form-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.losyoupass {
  color: #f44336!important;
  cursor: pointer;
}
.Highlight__section__homes h3 {
  font-size: 37px;
  line-height: 47px;
  font-weight: 500;
  margin-bottom: 17px;
}
.Highlight__section__homes .product__features {
  margin-bottom: 12px;
  margin-top: 23px;
  border-bottom: 1px solid lavender;
  padding-bottom: 17px;
}
.product__sqfsss__highligtsss {
  background-color: white;
  padding: 17px;
  border-radius: 7px;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  position: relative;
  width: 90%;
  margin: -87px auto 0;
}
.product__thumb__highligtsss {
  margin-top: 10px;
}
.product__sqfsss__highligtsss .featured__amount {
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}
.Highlight__section__homes .featured__amount p {
  color: var(--primary-color)!important;
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 0;
}
.product__thumb__highligtsss img {
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 20px 59px 0 rgb(0 0 0 / 14%);
  height: 460px;
  object-fit: cover;
  object-position: center top;
  margin: 0 auto;
  display: block;
}
img.notsss {
  object-fit: contain;
}
.product__content__desc{
  margin-bottom: 25px;
  border-top: 1px solid #e1e1e1;
  padding-top: 23px;
}
.Highlight__section__homes .product__content__desc p{
  color: #929292!important;
  overflow: unset!important;
  text-overflow: unset!important;
  display: -webkit-box!important;
  -webkit-line-clamp: unset!important;
  -webkit-box-orient: unset;
}
.Highlight__section__homes .product__content__desc p span{
  color: #000000!important;
}
.product__desc__mnmnmsss2aaa {
  border-top: 1px solid #d8d8d8;
  padding-top: 17px;
  height: 347px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.Highlight__section__homes .highqsqsqs3 ul.sqftcenfmsss {
  right: 117px;
  width: 87px;
}
.product__sqfsss__highligtsss  .product__features li svg {
  font-size: 18px;
  vertical-align: middle;
}
.Highlight__section__homes .highqsqsqs3 ul.sqftcenfmsss li{
  margin-bottom: 0px;
}
.Highlight__section__homes .featured__locations {
  font-size: 18px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #00c194;
}
.Highlight__section__homes .featured__locations svg {
  color: #00c194;
}
.Highlight__section__homes .featured__locations span{
  color: #ff9956;;
}
.product__content__desc .product__desc {
  max-height: 197px;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.listing__typeqqqqsss {
  font-size: 17px;
  color: #fff;
  padding: 7px 17px 4px 17px;
  border-radius: 3px;
  display: inline-block;
  line-height: 27px;
  margin-right: 4px;
  white-space: nowrap;
  border: 1px solid var(--primary-color);
  font-weight: 500;
  background-color: var(--primary-color);
}
.agent__section__homes{
  padding: 17px 0 17px 0;
  position: relative;
}
.agent__section__homes h2 {
  font-size: 16px;
  text-align: center;
  color: #00c194;
}
.agent__section__homes h3 {
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 37px;
}
.product__box__agsss {
  padding: 40px;
  background-color: #F9F9F9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 37px;
  margin-right: 10px;
}
.product__box__agsssimg {
  width: 184px;
  height: 184px;
  margin-right: 35px;
  border-radius: 50%;
  margin-bottom: 0;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.product__box__agsssimg img {
  width: 100%;
  min-width: inherit;
  object-fit: cover;
  object-position: center center;
  height: 100%;
  transition: 0.4s;
}
.product__box__agsss:hover img {
  filter: grayscale(0);
}
.product__agssscontentsss h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 7px;
}
.link__btn__agsss {
  border: 1px solid #E2E2E2;
  border-radius: 7px;
  background-color: #fff;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #00c194;
  margin-top: 17px;
}
ul.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
}
ul.pagination li {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
  padding: 6px 17px;
  border: 1px solid #e1e5ee;
  border-radius: 4px;
  background-color: #fff;
}
ul.pagination li.active{
  background-color: #00a376;
  border-color: #00a376;
  color: white;
}
ul.pagination li.disabled{
  display: none;
}
.listsss__widgetsss{
  margin-bottom: 30px;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border-radius: 6px;
  border: none;
  padding: 25px 30px 30px;
  background-color: #fff;
  margin: 0 0 30px;
  position: sticky;
  top: 77px;
  align-self: start;
  overflow: auto;
}
.listsss__widgetsss:hover{
  overflow-x: hidden;
  overflow-y: scroll;
  height:100vh;
  padding-bottom: 77px;
}
.listsss__widgettitle {
  margin-bottom: 20px;
  font-size: 22px;
  color: #212121;
  line-height: 30px;
  font-weight: 500;
}
.product__box__widgets{
  display: grid;
  grid-row-gap: 15px;
  position: relative;
  border-bottom: 1px solid #e7e9ed!important;
  padding-bottom: 18px;
}
.product__thumb__widgets {
  min-width: 100%;
  max-width: 100%;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  max-height: 190px;
  position: relative;
}
.product__thumb__widgets img{
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.product__thumb__widgets img:hover{
  border-radius: 4px;
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
  min-height: 100px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.widgets__category {
  color: #acafbb;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.widgets__titlesss{
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}
.product__content__widgets .featured__locations {
  font-size: 16px;
  margin-bottom: 10px;
  color: #878c9f!important;
}
.product__content__widgets .featured__amount__widgets p {
  font-size: 20px;
  margin-bottom: 0;
  color: #00c194;
  font-weight: 600;
}
.product__box__widgets2{
  position: relative;
  display: flex;
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #e7e9ed!important;
  padding-bottom: 18px;
  align-items: center;
  margin-bottom: 3px;
}
.product__thumb__widgets2 {
  flex: 0 0 35%!important;
  min-height: 100px;
  position: relative;
  margin-right: 18px;
  overflow: hidden;
}
.product__thumb__widgets2 img{
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.product__thumb__widgets2 img:hover{
  border-radius: 4px;
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
  min-height: 100px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.widgets__titlesss2 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 4px;
}
.contacts__forms{
  padding: 67px 0 67px 0;
  position: relative;
  background-color: #F1F8F6;
}
.contacts__forms form {
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border-radius: 6px;
  border: none;
  padding: 47px 47px 47px;
  background-color: #fff;
  margin: 0 0 30px;
}
.contacts__forms h1{
  font-size: 30px;
  margin: 10px 0 37px;
  font-weight: 600;
  line-height: 26px;
}
.contacts__forms label {
  font-size: 18px;
  font-weight: 600;
  color: #455892;
  margin-bottom: 10px;
  display: inline-block;
  font-family: var(--font-family);
}
.contacts__forms .form__control {
  padding-left: 17px;
}
.aboutssss{
  padding: 67px 0 67px 0;
  position: relative;
  background-color: #F1F8F6;
}
.aboutssss2{
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border-radius: 6px;
  border: none;
  padding: 47px 47px 47px;
  background-color: #fff;
  margin: 0 0 30px;
}
.modal__reports2 {
  background-color: rgba(0, 0, 0, 0.59);
}
.ois__modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 11px 1px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto !important;
  border-radius: 7px;
}
.modal__content {
  position: relative;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  margin-top: 90px;
  margin-bottom: 70px;
  border-radius: 0px;
  outline: 0px;
}
@media (min-width: 768px){ 
.modal__content {
  width: 777px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  padding: 27px;
}
}
.ois__closesss {
  position: absolute;
  right: 15px;
  top: 10px;
  color: red;
  z-index: 1000;
  cursor: pointer;
}
.ois__closesss svg {
  font-size: 32px;
}
.modal__content h1 {
  font-size: 22px;
  margin-bottom: 27px;
  font-weight: 600;
}
.modal__content input {
  line-height: 53px;
  color: rgb(0, 0, 0);
  font-size: 17px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 17px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(228, 236, 242);
  border-image: initial;
}
.feedbacksss, .feedbacksss2 {
  background-color: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 600;
  float: left;
  margin-right: 17px;
  cursor: pointer;
  margin-bottom: 7px;
  padding: 7px 27px;
}
.feedbacksss2 {
  color: rgb(76, 76, 76);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(111, 110, 110);
  border-image: initial;
  border-radius: 0px;
}
.modal__content ul {
  list-style: none;
  padding: 0;
}
.feedbacksss {
  color: red;
  border-width: 1px;
  border-style: solid;
  border-color: red;
  border-image: initial;
  border-radius: 0px;
}
.modal__content .form-row {
  display: block;
  width: 100%;
}
.qsqsqviews h4 {
  margin-bottom: 17px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 4px;
  border-left: 4px solid rgb(244, 67, 54);
}
.modal__content input[type="checkbox"] {
  float: left;
  margin-right: 10px;
  margin-bottom: 7px;
}
.modal__content .lllqqqsss {
  float: none;
  display: inline-block;
  margin: 0;
}
.lllqqqsss {
  display: inline-block;
}
.lllqqqsss, .lllqqqsss2 input[type="checkbox"] {
  float: left;
  margin-right: 17px;
}
.modal__content input[type="checkbox"] {
  display: inline-block;
  width: auto;
  height: auto;
}
.modal__content input[type="checkbox"] {
  zoom: 1.35;
  margin-top: 2px;
  vertical-align: middle;
}
.priceamountsss p{
  font-size: 37px;
  font-weight: 700;
  color: var(--primary-color);
  text-align: right;
}
@-webkit-keyframes upsdown{0%{transform:translateY(-100px);visibility:hidden;opacity:0}100%{transform:translateY(0);visibility:visible;opacity:1}}
 @keyframes upsdown{0%{transform:translateY(-100px);visibility:hidden;opacity:0}100%{transform:translateY(0);visibility:visible;opacity:1}}
 
.aboutssss2 ul {
  margin-left: 24px;
  box-shadow: rgba(28, 30, 35, 0.07) 0px 3px 7px 0px;
  margin-bottom: 37px;
  padding: 27px;
  list-style: none;
}
.aboutssss2 h3 {
  font-weight: 500;
  margin-bottom: 17px;
  font-size: 20px;
}
.aboutssss2 ul li {
  font-size: 18px;
  margin-bottom: 7px;
  line-height: 1.8;
  color: rgb(99, 93, 93);
}
.aboutssss2 p{
  font-size: 18px;
  margin-bottom: 7px;
  line-height: 1.8;
  color: rgb(99, 93, 93);
}
.aboutssss2 h2 {
  font-weight: 500;
  margin-bottom: 17px;
  font-size: 27px;
  margin-top: 27px;
}
.aboutssss2 a {
  color: rgb(33, 150, 243);
}
.users__info {
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.users__info2 a{ 
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #dadada;
  float: left;
  padding: 5px;
  margin-right: 10px!important;
}
.users__info a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: #fff;
}
.users__contents h2 {
  font-size: 20px;
  margin-bottom: 17px;
  font-weight: 500;
  color: #212121;
}
.users__contents a {
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border: none;
  padding: 0 23px;
  line-height: 47px;
  border-radius: 30px;
  font-size: 17px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #eaf7f4;
  margin-right: 10px;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.infosss__prosss {
  background-color: #fff5e4;
  display: inline-block;
  font-size: 18px;
  padding: 14px 17px;
  margin-top: 37px;
}
.infosss__prosss svg {
  color: #ffaa3f;
  font-size: 22px;
  margin-right: 3px;
  margin-top: -4px;
  vertical-align: middle;
}
.infosss__prosss a{
  color: #008eff;
  font-weight: 500;
}
.listing__typeqqqqssshigsss {
 position: relative;
}
.listing__typeqqqqssshigsss .listing__type__badge{
  background-color: #ea8241;
  border: 1px solid #ea8241;
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
  margin-right: 4px;
  padding: 7px 17px 7px;
  white-space: nowrap;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
}
.cols__sharesss{
  position: relative;
}
.ois__sharsss {
  position: absolute;
  right: 0;
  bottom: -57px;
  background-color: #ffffff;
  padding: 14px;
  border-radius: 7px;
  border: 2px solid #00c194;
  display: flex;
}
.ois__sharsss .ois__sharsss__facebook {
  background-color: #1878f4!important;
}
.ois__sharsss .ois__sharsss__twitter {
  background-color: #1da1f3!important;
}
.ois__sharsss .ois__sharsss__linkedin {
  background-color: #0a66c3!important;
}
.ois__sharsss .ois__sharsss__whatsup {
  background-color: #00e676!important;
}
.ois__sharsss .ois__sharsss__email {
  background-color: #cc3c31!important;
}
.ois__sharsss button {
  width: 37px;
  height: 37px;
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 100%;
}
.ois__sharsss svg {
  width: 37px;
  height: 37px;
  color: #fff;
  padding: 7px;
}
.btn__homesss {
  fill: #494e4d;
  color: #ffffff;
  background-color: #494e4d;
  border-radius: 3px 3px 3px 3px;
  padding: 17px 40px 17px 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
  width: auto;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
}
.btn__homesss:hover {
  color: #00c194;
  background-color: #dceeea;
}
.btn__cols {
  display: flex;
  justify-content: space-between;
}
.btn__cols p:first-child{
  background-color: #ff1100;
}
.shows__features{
  overflow: unset!important;
  height: auto!important;
}
.featured__section__homes ul.sqftcenfmsss {
  right: 0px;
  display: flex;
  width: max-content;
  top: -47px;
}
.featured__section__homes ul.sqftcenfmsss li {
  margin-right: 7px;
  font-size: 16px!important;
  margin-bottom: 0;
}
.homes__section__homes ul.sqftcenfmsss {
  right: 0px;
  display: flex;
  top: -47px;
  width: max-content;
}
.homes__section__homes ul.sqftcenfmsss li {
  margin-right: 7px;
  font-size: 16px!important;
  margin-bottom: 0;
}
.Highlight__section__homes ul.sqftcenfmsss {
  right: -67px;
}
.seller__section__homes ul.sqftcenfmsss {
  right: 0px;
  display: flex;
  top: -47px;
  width: max-content;
}
.seller__section__homes ul.sqftcenfmsss li {
  margin-right: 7px;
  font-size: 16px!important;
  margin-bottom: 0;
}
.product__overview ul.sqftcenfmsss {
  right: -20px;
  list-style: none;
}
.product__overview ul.sqftcenfmsss li {
  margin-top: 0px;
  margin-bottom: 0;
  width: 100%;
}
.product__overview .amenities__list ul.sqftcenfmsss {
  right: 127px;
  list-style: none;
  top: -44px;
  display: flex;
  flex-direction: row;
  width: 177px;
}
.product__overview .amenities__list  ul.sqftcenfmsss li{
  background-color: #ffffff00;
  line-height: 27px;
  padding-left: 0px;
  margin: 0px 0;
}
.commsoonsss {
  background-color: #f2f8f6;
  min-height: 87vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.commsoonsss h2 {
  font-size: 37px;
  font-weight: 600;
  color: #00c194;
}
.product__box__blogsss {
  background-color: #fff;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  display: flex;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 20px 20px 20px 20px;
  transition: all .3s ease-in-out;
}
.product__thumb__blogsss {
  background-color: #f5f6fb;
  border-radius: 6px;
  height: 200px;
  max-width: 250px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  margin-right: 27px;
}
.product__thumb__blogsss img {
  display: block;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  object-position: center top;
}
.product__blogsss__list h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 8px;
  padding-top: 5px;
}
.product__blogsss__list .product__desc{
  -webkit-line-clamp: 4!important;
    -webkit-box-orient: vertical;
    display: -webkit-box!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    max-height: 137px;
}
.product__blogsss__list hr {
  margin: 0px 0 10px 0;
  color: #888787;
  height: 0.7px;
}
.product__blogsss__list hr:not([size]) {
  height: 0.7px;
}
.property__section__view.property__article__view {
  padding: 37px 0px 67px;
}
.oversartilsss {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.property__article__view .fl__wrap__two {
  font-size: 27px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 47px;
}
.product__thumb__blogsss2 {
  border-radius: 6px;
  height: auto;
  max-width: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 27px;
  display: inline-block;
  float: left;
  margin-bottom: 27px;
}
.product__thumb__blogsss2 img {
  display: block;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  object-position: center top;
}
.property__article__view .tab__content {
  padding: 47px 46px 47px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 7px;
}
.property__article__view .fl__wrap__twosss svg {
  vertical-align: middle;
  margin-top: -2px;
  color: #00c194;
}
.property__article__view .product__thumb__psgsssgrid {
  height: auto;
}
.property__article__view .tab__content__headingsss {
  font-weight: 600;
  margin-bottom: 27px;
}
.property__article__view .product__thumb__blogsss {
  height: 137px;
  max-width: 197px;
  min-width: 196px;
}
.property__article__view .product__blogsss__list .product__desc {
  -webkit-line-clamp: 3!important;
}
.profsssimagesss {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.profsssimages2 {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #00c194;
  border-radius: 100%;
  margin-right: 17px;
  border: 1px solid #00c194;
}
.profsssimages2 span {
  font-size: 27px;
  font-weight: 600;
  margin-top: 0;
}
.profsssimagesss img{
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #00c194;
  border-radius: 100%;
  margin-right: 17px;
  border: 1px solid #00c194;
}
.Highlight__newdesignsss {
  background-color: #ffffff;
}
.Highlight__newdesignsss__bg{
  position: relative;
}
.Highlight__newdesignsss__bg2 {
  position: absolute;
  background-image: url(https://hiehomes.com/uploads/highlisss.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 600px;
  width: 100%;
  top: 0;
}
.Highlight__newdesignsss__bg .fl__wrap__two {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 14px;
}
.Highlight__newdesignsss__bg .fl__wrap__two h3 {
  text-align: center;
  font-size: 47px;
  margin-bottom: 37px;
}
.Highlight__newdesignsss__bg .fl__wrap__two p{
  text-align: center;
}
.Highlight__newdesignsss__bg .shares__views {
  display: flex;
  position: relative;
  float: unset;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.Highlight__newdesignsss__bg .picedetails__views {
  position: relative;
  margin-top: 7px;
}
.Highlight__newdesignsss__bg .fl__wrap__two {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.Highlight__newdesignsss__bg .fl__wrap {
  position: absolute;
  right: 0;
  margin-bottom: 0;
  top: 77px;
}
.Highlight__newdesignsss .widget {
  background-color: #fff0;
}
.Highlight__newdesignsss .fl__wrap__two p {
  color: #6d6f77;
  text-transform: capitalize;
}
.Highlight__newdesignsss .priceamountsss p {
  color: var(--primary-color)!important;
  font-size: 47px;
  margin-top: 17px;
  font-weight: 600;
}
.topqqqsqsqsss{
  margin-top: 110px;
}
.Highlight__newdesignsss .tab__content__shows li {
  margin-right: 10px;
  font-weight: 600;
  color: #212121;
  padding: 0 17px;
  height: 52px;
  border: 1px solid #eee;
  position: relative;
  display: block;
  line-height: 52px;
  border-radius: 5px;
  background-color: #fff0;
  min-width: auto;
  text-align: center;
  cursor: pointer;
}
.Highlight__newdesignsss .tab__content__shows li svg {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 4px;
}
.Highlight__newdesignsss__bg3 {
  position: absolute;
  top: 337px;
  right: 0;
}
.Highlight__newdesignsss__bg4 {
  position: absolute;
  top: 380px;
  right: 227px;
  width: 60px;
}
.Highlight__newdesignsss__bg1 {
  position: absolute;
  top: 97px;
  left: 87px;
  width: 47px;
}
.Highlight__newdesignsss__bg33 {
  position: absolute;
  top: 27px;
  right: 47px;
  width: 47px;
}
.Highlight__newdesignsss__bg22 {
  position: absolute;
  top: 437px;
  left: 38%;
}
.hidhlqqqsss .product__thumb__psgsssgrid {
  height: auto;
}
.product__thumb__blogsss {
  height: 137px;
  max-width: 197px;
  min-width: 196px;
}
.product__box__blogsssallsss {
  background-color: #fff;
  background: #fff;
  border-radius: 6px;
  display: flex;
  margin-top: 37px;
  margin-bottom: 30px;
  overflow: hidden;
  transition: all .3s ease-in-out;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.product__thumb__blogsssallsss{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 17px;
}
.product__thumb__blogsssallsss img {
  display: block;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  object-position: center top;
}
.product__box__blogsssallsss2 {
  background-color: #ebf7f5;
  background: #ebf7f5;
  padding: 37px;
  border-radius: 10px;
  display: flex;
  margin-top: 27px;
  padding-bottom: 27px;
  margin-bottom: 0px;
  overflow: hidden;
  transition: all .3s ease-in-out;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid #00c194;
}
.product__box__blogsssallsss2 .product__blogsss__list h3 {
  padding-top: 0;
}
.linksssqqq {
  color: #2196f3;
}
.linksssqqq2 {
  padding: 17px;
  background-color: white;
  border-radius: 7px;
  margin-bottom: 27px;
}
.product__box__blogsssallsss2 .Video__innerpage2 {
  margin-top: 27px;
}
.product__thumb__socialsss{
  margin-top: 37px;
}
.product__thumb__socialsss p{
  font-weight: 500;
}
.Highlight__newdesignsss .ois__sharsss {
  position: absolute;
  right: unset;
  bottom: -73px;
}
.Highlight__sec__homesh6 {
  font-size: 16px;
  text-align: center;
  color: #00c194;
}
.Highlight__sec__homesh1 {
  font-size: 47px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 37px;
}
.Highlight__sec__homesh1 span{
  background: linear-gradient(to bottom right,#bdd0bc 0%,#ffaf57 50%,#fe6053 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Highlight__section__homes {
  padding: 67px 0 117px 0;
  position: relative;
  background-color: #fff4e6;
  background: linear-gradient(to right,#ecfdf5 22%,#ffffff 47%,#ffffff 60%,#fff4e6 100%);
  margin-bottom: 77px;
}
.highqsqsqs3{
  padding-bottom: 67px;
}
.highlight__Widgetsss {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.highlight__Widgetsss2 {
  background-color: #FFFFFF;
  background-image: url(https://hiehomes.com/uploads/shape.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 10%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 47px 70px 47px 70px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.highlight__left h2 {
  font-size: 27px;
  margin-bottom: 0;
  margin-right: 37px;
}
.highlight__right .btn__homesss{
  color: #ffffff;
  background-color: #1d1d1d;
}
.divederhisss {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  left: 0;
  width: 100%;
}
.divederhisss1{
  width: 25%;
  max-width: 25%;
  border-top: 4px solid #F69F29;
}
.divederhisss2{
  width: 40%;
  max-width: 40%;
  border-top: 4px solid var(--primary-color);
}
.divederhisss3{
  width: 25%;
  max-width: 25%;
  border-top: 4px solid #F69F29;
}
.divederhisss4{
  width: 10%;
  max-width: 10%;
  border-top: 4px solid var(--primary-color);
}
.product__thumb__highligtsssqqq {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.proxaqqq {
  margin-left: -197px;
  margin-top: 37px;
}
.proxaqqq h3 {
  font-size: 37px;
  color: #000000;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(--font-family-two);
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
}
.product__desc__mnmnmsss {
  margin-bottom: 25px;
  border-top: 0 solid #e1e1e1;
  padding-top: 57px;
  padding-left: 17px;
  height: 347px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
ul.product__desc__mnmnmsss2 {
  padding: 13px;
  list-style: none;
  position: absolute;
  right: 0;
  top: 40%;
  max-width: 117px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgb(216 208 196) 0px 12px 40px 0px;
}
ul.product__desc__mnmnmsss2 li {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  color: #000000;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  line-height: 37px;
  text-align: center;
}
ul.product__desc__mnmnmsss2 li svg{
  color: #00c194;
  margin-right: 7px;
  vertical-align: middle;
}
.product__thumb__highligtsssqqq .listing__typeqqqqssshigsss {
  position: absolute;
  top: 116%;
}
.proxaqqq2 {
  margin-left: -163px;
  margin-top: 67px!important;
  align-items: center;
}
.Highlight__section__homes .featured__amount.proxaqqq2 p {
  color: #ea8241!important;
  font-size: 47px;
  font-weight: 600;
  margin-bottom: 0;
}

.product__desc__mnmnmsss .product__desc, .product__desc__mnmnmsss .product__desc p, .product__desc__mnmnmsss .product__desc p b, .product__desc__mnmnmsss .product__desc p strong, .product__desc p span, .product__desc__mnmnmsss .product__desc p em {
  color: #000000!important;
}
.Highlight__section__homes .proxaqqq .featured__locations {
  color: black;
  font-size: 22px;
}
.Highlight__section__homes .proxaqqq .featured__locations svg{
  color: #00c194;
}
.product__thumb__highligtsssqqq img{
  border-radius: 10px;
  object-fit: cover;
  object-position: center top;
}
.rellax2 {
  max-width: 100%;
  width: 387px;
  height: 291px;
  left: 5%;
  box-shadow: rgb(74 74 174 / 40%) 0px 12px 30px 0px;
  transform: translate3d(0px,6px,0px);
}
.rellax3 {
  max-width: 100%;
  width: 337px;
  height: 307px;
  top: 47%;
  right: 0;
  box-shadow: rgb(74 74 174 / 50%) 0px 12px 40px 0px;
  transform: translate3d(0px,43px,0px);
  position: absolute;
 
}
.rellax4 {
  top: 140%;
  max-width: 100%;
  width: 306px;
  height: 237px;
  left: 20%;
  box-shadow: rgb(74 74 174 / 40%) 0px 12px 30px 0px;
  transform: translate3d(0px,6px,0px);
  border-radius: 10px;
  position: absolute;
 
}
.agents__new__qqq .product__thumb__psgsssgrid {height: auto;}
.notfoundsss {
  background-color: #fff;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  width: 100%;
  float: left;
  padding: 0 0 47px;
  position: relative;
}
.notfoundsss h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  color: #000;
}
.notfoundsss p {
  font-size: 4em;
  text-align: center;
  font-weight: 100;
  color: #000;
}
.notfoundsss .button {
  background-color: #00c194;
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 7px 17px;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
  transition: all .3s linear;
  display: inline-block;
  width: auto;
}

.product__desc__articlsss, .product__desc__articlsss p, .product__desc__articlsss p b, .product__desc__articlsss p strong, .product__desc__articlsss p span, .product__desc__articlsss p em{
  color: #000000!important;
  font-size: 18px!important;
  line-height: 33px!important;
  margin-bottom: 10px!important;
  background-color: transparent!important;
}
.product__desc, .product__desc p, .product__desc p b, .product__desc p strong, .product__desc p span, .product__desc p em{
  color: #000000!important;
  font-size: 18px!important;
  line-height: 33px!important;
  margin-bottom: 10px!important;
  font-weight: 400!important;
  background-color: transparent!important;
}
.product__desc h1, .product__desc h2, .product__desc h3, .product__desc h4, .product__desc h5, .product__desc h6, .product__desc h1 span, .product__desc h2 span, .product__desc h3 span, .product__desc h4 span, .product__desc h5 span, .product__desc h6 span, .product__desc h1 em, .product__desc h2 em, .product__desc h3 em, .product__desc h4 em, .product__desc h5 em, .product__desc h6 em{
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}







@media (max-width: 768px){ 
  .aboutssss2 {padding: 7px 17px 17px;margin: 0 0 27px;}
  .agent__meta {top: unset;bottom: 17px;}
  .aboutssss2 ul {margin-left: 0;padding: 17px;word-break: break-word;}
  .product__box__blogsss {flex-direction: column;flex-wrap: wrap;align-items: center;}
  .property__article__view .tab__content__shows {margin-top: 13px;}
  .property__article__view .tab__content__shows li {min-width: auto;padding: 0 10px;height: auto;line-height: 47px;}
  .property__article__view .tab__content {padding: 27px 17px 27px;}
  .featured__section__homes {padding: 37px 0 37px 0;}
  .featured__section__homes h3 {font-size: 24px;margin-bottom: 17px;}
  .featured__section__homes .product__content__list {padding-left: 0;padding-right: 0;margin-top: 7px;}
  .product__thumb__blogsss2 {max-width: 63%;margin-right: 10px;margin-bottom: 10px;}
  .tab__content__shows li {min-width: auto;margin-right: 7px;font-size: 16px;padding: 0 17px;line-height: 47px;height: auto;margin-bottom: 7px;}
  .featured__section__homes li span {width: 16px;height: 16px;margin-right: 10px;}
  .featured__section__homes p.featured__category {font-size: 18px;text-align: center;color: #666666;}
  .featured__section__homes .product__content__list h3 {margin-bottom: 4px;padding-top: 4px;text-align: center;}
  .featured__section__homes .featured__locations {text-align: center;}
  .featured__section__homes .featured__amount p {font-size: 27px;text-align: center;}
  .featured__section__homes .product__features {justify-content: space-around;}
  .featured__section__homes .product__box__featured {padding: 17px 17px 17px 17px;}
  .product__thumb__featured {min-width: 247px;max-width: 260px;height: auto;}
  .featured__section__homes .product__features.shows__features{ height: auto; }
  .featured__section__homes .featured__locations {font-size: 18px;}
  .featured__section__homes .product__features li {font-size: 16px;}
  .homes__section__homes {padding: 47px 0 47px 0;}
  .Highlight__section__homes {padding: 47px 0 47px 0;}
  .agent__section__homes {padding: 47px 0 47px 0;}
  .product__thumb__highligtsss {margin-top: 47px;}
  .product__thumb__highligtsss img {height: auto;}
  .seller__section__homes {padding: 7px 0 7px 0;}
  .product__box__agsss {flex-direction: row;flex-wrap: wrap;justify-content: center;}
  .product__agssscontentsss h3 {text-align: center;}
  .product__agssscontentsss a {text-align: center;margin: 0 auto;display: table;}
  .link__btn__agsss {margin: 17px auto 0;}
  .rent__section__homes h3 {font-size: 27px;text-align: center;justify-content: center;flex-wrap: wrap;align-items: center;}
  .rent__section__homes h3 a {display: none;}
  .rent__section__homes h2 {text-align: center;}
  .product__rent__list {margin: 0 auto;display: table;text-align: center;}
  .product__box__rent {flex-direction: row;flex-wrap: wrap;justify-content: center;align-items: center;padding: 10px 7px 10px 7px;}
  .rent__section__homes .product__content__rent .product__content__list {padding-left: 10px;padding-right: 10px;margin: 0 auto;display: table;text-align: center;width: 100%;}
  .rent__section__homes .product__content__list h3 {text-align: center;margin: 7px auto;}
  .video__section__homes {min-height: auto;padding: 60px 0 47px 0;}
  .rent__section__homes {padding: 10px 0 47px 0;}
  .video__section__text {font-size: 57px;line-height: 77px;margin-top: 47px;margin-left: 0;text-align: center;}
  .pg__sec__homes{padding: 47px 0 47px 0;}
  .aggsss__left {display: none;}
  .aggsss__right {display: none;}
  .agent__homes {justify-content: center;flex-direction: row;flex-wrap: wrap;padding: 0 27px 0 27px;}
  .Agent__section__homes h3 {font-size: 27px;text-align: center;margin-bottom: 17px;}
  .Agent__section__homes p {margin-bottom: 17px;text-align: center;}
  .Agent__section__homes {padding: 47px 0 47px 0;}
  .sortsss2sss {flex-direction: row;flex-wrap: wrap;justify-content: center;}
  .sortsss2sss p {margin-bottom: 10px;}
  .col__left__15 {padding-left: 0;}
  .page__sectionsss  .product__box__psgsss {padding: 0px 0 10px 0px;flex-direction: row;flex-wrap: wrap;justify-content: center;align-items: center;}
  .page__sectionsss  .product__thumb__psgsss {min-width: 100%;max-width: 100%;height: auto;max-height: 337px;}
  .product__content__psgsss {margin-top: 17px;}
  .page__sectionsss {padding: 37px 0 37px 0;}
  .layoutsss {display: none;}
  .property__section__view {padding: 47px 0px 47px;}
  .fl__wrap {margin-bottom: 17px;display: flex;flex-direction: row;justify-content: center;}
  .picedetails__views {margin-top: 0;display: flex;flex-direction: row;justify-content: center;flex-wrap: wrap;align-items: center;}
  .priceamountsss p {margin-bottom: 0;}
  .shares__views {display: flex;float: unset;flex-direction: row;justify-content: center;}
  .fl__wrap__two {text-align: center;}
  .proimgqqq li {width: 117px;height: 97px;}
  .switch__checkbox{display: none;}
  .widget {padding: 17px 17px 17px;}
  .Overview__list li {margin-top: 10px;margin-bottom: 10px;width: auto;margin-right: 17px;}
  .amenities__list li {width: 100%;line-height: 37px;background: #ffffff;}
  .amenities__list li:nth-child(2n+2){background-color: #EAF7F4;}
  .product__sidebar {margin-bottom: 10px;}
  .page__sectionsss .agentss__headings__sec {display: flex;flex-direction: column;justify-content: center;align-items: center;flex-wrap: wrap;}
  .page__sectionsss .agents__sec .product__content__list .product__desc {text-align: center;}
  .page__sectionsss span.agent__locsss {margin: 10px auto;display: table;}
  .page__sectionsss .link__btns {margin: 0 auto 17px;display: block;}
  .form-control-maps {z-index: 77;}
  .css-b62m3t-container {z-index: 77;}
  .agent__wrap__content {top: 17px;left: 0px;flex-direction: column;align-items: center;text-align: center;}
  .agent__wrap__img {height: 347px;}
  img.notsss {object-fit: cover;}
  .agent__area {flex: 1;padding-right: 0;display: flex;flex-direction: column;align-items: center;word-break: break-word;margin-top: 17px;}
  .tab__content__shows li {min-width: 137px;}
  .tab__content {padding: 17px 17px 17px;}
  .product__thumb__psgsssgrid {height: auto;}
  .contacts__forms {padding: 27px 0 27px 0;}
  .contacts__forms form {padding: 27px 17px 27px;margin: 0 0 30px;}
  .video__section__contents h2 {font-size: 27px;line-height: 37px;}
  .video__section__contents {padding: 27px 27px 27px 27px;}
  .btn__homesss {padding: 17px 17px 17px 17px;}
  .Highlight__section__homes .featured__amount p {font-size: 27px!important;}
  .listsss__widgetsss {display: none;}
  .agent__logo img {height: 187px;}
  .Highlight__newdesignsss__bg .fl__wrap {display: none;}
  .Highlight__newdesignsss__bg1 {display: none;}
  .Highlight__newdesignsss__bg3 {display: none;}
  .Highlight__newdesignsss__bg4 {display: none;}
  .Highlight__newdesignsss__bg33 {display: none;}
  .Highlight__newdesignsss__bg22 {display: none;}
  .Highlight__newdesignsss__bg .fl__wrap__two {margin-top: 0;}
  .Highlight__newdesignsss__bg .fl__wrap__two h3 {font-size: 34px;margin-bottom: 17px;}
  .Highlight__newdesignsss .priceamountsss p {font-size: 27px;margin-top: 0;}
  .sdawsdeawewaq {padding: 47px 10px 17px 10px;}
  .topqqqsqsqsss {margin-top: 30px;}
  .Highlight__newdesignsss .tab__content__shows li {padding: 0 10px;height: auto;line-height: 37px;margin-bottom: 10px;}
  .Highlight__newdesignsss .tab__content__shows {padding: 0 10px 0 10px;}
  .Highlight__newdesignsss .tab__content__shows li svg {display: none;}
  .Highlight__newdesignsss .product__overview {margin: 0px 0 30px;}
  .Highlight__newdesignsss .linksssqqq2 {margin-bottom: 0;}
  .Highlight__newdesignsss  .fl__wrap__img {margin-top: 0;}
  .Highlight__sec__homesh1 {font-size: 34px;margin-bottom: 0;}
  .product__thumb__highligtsss {margin-top: 0;}
  .product__sqfsss__highligtsss {padding: 7px;border-radius: 0;width: 100%;margin: 17px auto 27px;}
  .Highlight__section__homes .col-sm-12{padding-right: 4px;} 
  .highlight__Widgetsss2 {padding: 17px 17px 17px 17px;position: relative;top: 0;display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: center;}
  .Highlight__section__homes {margin-bottom: 37px;}
  .highlight__left h2 {font-size: 20px;margin-bottom: 17px;margin-right: 0;text-align: center;}
  .product__box__blogsssallsss2 {padding: 17px;}
  .proxaqqq2 {margin-left: 0;margin-top: 37px!important;}
  .rellax2 {width: auto;height: auto;left: 0;}
  .rellax3 {width: auto;height: auto;top: 0;position: relative;}
  .rellax4 {display: none;}
  .product__thumb__highligtsssqqq .listing__typeqqqqssshigsss {display: none;}
  .proxaqqq {margin-left: 0;margin-top: 77px;}
  ul.product__desc__mnmnmsss2 {top: 10%;}
  .highqsqsqs3 {margin-top: 37px;padding-bottom: 37px;}
  .product__desc__mnmnmsss {margin-bottom: 10px;padding-top: 10px;padding-left: 0;}

}

@media (min-width: 768px) and (max-width: 991px) { 
  .Highlight__newdesignsss__bg .fl__wrap__two h3 {font-size: 34px;}
  .Highlight__newdesignsss__bg33 {top: 10px;right: 47px;width: 27px;}
  .Highlight__newdesignsss__bg4 {top: 347px;right: 137px;width: 47px;}
  .Highlight__newdesignsss__bg1 {top: 90px;left: 14px;width: 37px;}
  .Highlight__newdesignsss .tab__content__shows li {margin-right: 7px;padding: 0 10px;}
  .tab__content__shows {margin-bottom: 0;}
  .tab__content__shows li {padding: 0 20px;margin-bottom: 10px;}
  .mr__left__27 {margin-left: 7px!important;}
  .btn__default__agentsss {padding: 3px 17px 3px 10px!important;}
  .androidsss ul {padding: 0;}
  .androidsss ul li {margin-right: 7px;}
  .androidsss {margin-top: 0!important;margin-left: 0!important;}
  .product__thumb__featured {min-width: 300px;max-width: 300px;height: auto;max-height: 237px;}
  .product__box__featured {padding: 14px 10px 14px 10px;}
  .product__content__featured {margin-top: 10px;}
  .featured__section__homes .product__features li {font-size: 16px;}
  .featured__amount p {margin-bottom: 10px;font-size: 27px;margin-top: 10px;}
  .featured__locations {font-size: 16px;margin-bottom: 10px;color: #585656!important;}
  .featured__section__homes .product__content__list h3 {font-size: 20px;margin-bottom: 7px;padding-top: 7px;}
  .Highlight__section__homes .featured__amount {margin-bottom: 37px;}
  .seller__section__homes {padding: 10px 0 67px 0;}
  .agent__section__homes {padding: 7px 0 7px 0;}
  .product__box__agsssimg {width: 117px;height: 117px;margin-right: 7px;}
  .product__box__agsss {padding: 10px;}
  .product__agssscontentsss h3 {font-size: 20px;line-height: 27px;}
  .product__box__rent .product__features li {font-size: 18px;}
  .video__section__homes {min-height: auto;padding: 47px 0 47px 0;}
  .pg__sec__homes{padding: 47px 0 47px 0;}
  .video__section__text {line-height: 107px;margin-left: 0px;font-size: 70px;}
  .video__section__contents {padding: 32px 27px 32px 27px;}
  .aggsss__left{display: none;}
  .search__widget {padding: 10px 7px 10px!important;}
  .col__left__20 {padding-left: 7px;}
  .sortsss {flex-wrap: wrap;}
  .page__sectionsss  .product__content__list {padding-left: 17px;padding-right: 10px;}
  .product__thumb__psgsss {min-width: 217px;max-width: 217px;height: 197px;}
  .product__box__widgets2 {flex-direction: column;flex-wrap: wrap;align-content: center;}
  .listsss__widgetsss {padding: 10px 10px 10px;}
  .agentss__headings__sec {flex-direction: row;margin-bottom: 7px;flex-wrap: wrap;}
  .contacts__forms {padding: 37px 0 37px 0;}
  .rellax2 {width: auto;height: auto;left: 0;}
  .rellax3 {display: none}
  .rellax4 {display: none;}
  .product__thumb__highligtsssqqq {align-items: center;justify-content: center;}
  .proxaqqq {margin-left: 0;margin-top: 37px;}
  .product__thumb__highligtsssqqq .listing__typeqqqqssshigsss{display: none;}
  .product__desc__mnmnmsss {padding-top: 17px;padding-left: 0;}
  .proxaqqq2 {margin-left: 0;margin-top: 0px!important;align-items: center;}
  .highqsqsqs3 {padding-bottom: 0;}
  .highlight__Widgetsss2 {padding: 27px 27px 27px 27px;top: 0px;}
  .highlight__left h2 {font-size: 22px;margin-right: 17px;}








}


@media (max-width: 992px){ 
  .product__box__featured {flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: center;}


}
@media (min-width: 992px){ 
  .agent__wrap__content {
    align-items: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) { 
  .main-title {margin-top: 37px;margin-bottom: 20px;}
  .product__content__list h3 {font-size: 16px!important;}
  .btn__default__agentsss {margin-top: 7px!important;}
  .new__section__homes2 h2 {margin-bottom: 10px!important;}
  .new__section__homes2 ul li {font-size: 17px;}
  .new__section__homes2s { padding: 27px 27px 27px 27px;  }
  .new__section__homes2 { margin: 60px 0px 37px 0px;}
  .ccone {right: -70px;top: 47px;width: 57%;}
  .product__thumb__rent img {max-width: 177px;height: 137px;}
  .product__box__agsss {padding: 17px;}
  .product__thumb {min-height: 177px;}
  .product__thumb img {max-height: 177px;}
  .product__type {top: 10px;left: 10px;}
  .ois__sharsss {bottom: -47px;padding: 10px; border-radius: 3px; border: 1px solid #00c194;}
  .aggsss__left {width: 127px;left: 0px;top: 36px;}
  .product__thumb__psgsssgrid {height: 210px;}
  .property__section__view {padding: 37px 0px 67px;}
  .product__overview .amenities__list ul.sqftcenfmsss {right: 0;}
  .rellax2 {width: 247px;height: 197px;left: 0;}
  .rellax3 {width: 267px;height: 197px;top: 57%;right: 0;}
  .rellax4 {width: 277px;height: 207px;left: 20%;}
  .proxaqqq2 {margin-left: 0;margin-top: 27px!important;}
  .Highlight__section__homes .featured__amount.proxaqqq2 p {font-size: 30px;}
  .highqsqsqs3 {padding-bottom: 0px;}
  .highlight__Widgetsss2 {padding: 27px 27px 27px 27px;}
  .proxaqqq h3 {font-size: 30px;}
  .product__desc__mnmnmsss {padding-top: 17px;padding-left: 10px;height: 317px;}
  .rent__section__homes {padding: 0px 0 67px 0;}
  .video__section__text {font-size: 60px;margin-top: 117px;margin-left: 17px;line-height: 77px;}
  .video__section__contents {padding: 37px 37px 37px 37px;}
  
  .video__section__homes {min-height: 497px;}
  .Highlight__newdesignsss__bg .fl__wrap__two {margin-top: 17px;}
  .rtcl__badge {margin-right: 7px;}
  .Highlight__newdesignsss__bg .fl__wrap__two h3 {margin-bottom: 27px;font-size: 30px;}
  .Highlight__newdesignsss__bg .fl__wrap {right: 7px;top: 147px;}
  .Highlight__newdesignsss__bg22 {top: 367px;left: 17%;}
  .Highlight__newdesignsss__bg4 {top: 317px;right: 147px;}
  .Highlight__newdesignsss__bg1 {top: 67px;left: 37px;}
  .Highlight__newdesignsss__bg33 {top: 10px;right: 27px;
    width: 37px;}
  .tab__content__shows li {padding: 0 14px;height: 47px;line-height: 44px;}



}
@media (min-width: 1200px) and (max-width: 1348px){ 
  .video__section__text {font-size: 67px;line-height: 94px;}
  .Agent__section__homes h3 {font-size: 34px;}
  .new__section__homes2s {padding: 37px 37px 37px 37px;}
  .btn__default__agentsss {margin-top: 0px;}
  .new__section__homes2 {margin: 60px 0px 90px 0px;}
  .cc3 {width: 76%;}
  .aggsss__left {left: 7px;top: 4px;}
  .video__section__homes {min-height: 517px;}
  .proxaqqq {margin-left: -137px;}
  .product__desc__mnmnmsss {padding-top: 17px;height: 317px;}
  .proxaqqq2 {margin-left: -97px;margin-top: 67px!important;}
  .Highlight__newdesignsss__bg1 {top: 94px;left: 60px;width: 37px;}
  .Highlight__newdesignsss__bg33 {width: 37px;}
  .Highlight__newdesignsss__bg22 {top: 400px;left: 34%;}
  .Highlight__newdesignsss__bg4 {top: 360px;right: 227px;width: 47px;}
  .Highlight__newdesignsss__bg1 {width: 37px;}
  .Highlight__newdesignsss__bg .picedetails__views {margin-top: 0px;}
  .Highlight__newdesignsss__bg .fl__wrap__two h3 {font-size: 40px;}
  .tab__content__shows li {padding: 0 24px;}




  
}
@media (min-width: 1349px) and (max-width: 1400px) { 
  .btn__default__agentsss {margin-top: 0!important;}
  .new__section__homes2s {padding: 40px 40px 40px 40px;}
  .new__section__homes2 h2 {margin-bottom: 17px;}
  .cc3 {width: 64%;}
  .video__section__text {font-size: 67px;}
  .banners__content {padding-top: 7%;}
  .btn__default__agentsss {margin-top: 20px;}
  .main__title {font-size: 42px;margin-bottom: 44px;letter-spacing: 1px;}
  .aggsss__left {left: 44px;top: 4px;}
  .ois__sharsss {bottom: -33px;padding: 10px;border-radius: 3px;border: 1px solid #00c194;}
  .rellax2 {width: 347px;height: 277px;}
  .proxaqqq {margin-left: -167px;margin-top: 30px;}
  .product__desc__mnmnmsss {padding-top: 37px;height: 343px;}
  .rellax3 {width: 327px;height: 297px;top: 44%;}
  .rellax4 {top: 144%;width: 317px;height: 227px;left: 17%;}
  .proxaqqq2 {margin-left: -83px;margin-top: 40px!important;}
  .Highlight__newdesignsss__bg .fl__wrap__two h3 {font-size: 42px;}
  .Highlight__newdesignsss__bg33 {width: 37px;}
  .Highlight__newdesignsss__bg22 {top: 400px;left: 34%;}
  .Highlight__newdesignsss__bg4 {top: 360px;right: 227px;width: 47px;}
  .Highlight__newdesignsss__bg1 {width: 37px;}
  .Highlight__newdesignsss__bg .picedetails__views {margin-top: 0px;}
  .upgrade__highlights2 {padding: 27px 27px 7px;}
  .highsss__widget__menus {margin-bottom: 24px;margin-right: 24px;padding: 14px 24px;}
  .tab__content__shows li {height: 57px;line-height: 52px;padding: 0 24px;}


}

.colsagents{flex-wrap: wrap;}
.load__more__btn{
  background-color: #fff;
  border: 1px solid #e1e5ee;
  border-radius: 4px;
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
  padding: 6px 17px;
}
.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
}
.termsss{
  margin-top: 27px;
}
.paycurrencysss {
  display: flex;
  flex-flow: row;
  gap: 28px;
  list-style: none;
  color: #212121;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 17px;
  padding-left: 0;
}
.paycurrencysss li svg {
  margin-right: 4px;
  font-size: 27px;
  vertical-align: middle;
}
.paycurrencysss__lists {
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 15%);
  border: 1px solid #e5e5e5;
  padding: 7px 17px 7px 17px;
  border-radius: 10px;
}
.paycurrencysss__lists:hover{
  box-shadow: inset 0px 0px 0px 0px rgb(67 142 252);
  border: 2px solid #438efc;
}
.paycurrencysss__active {
  box-shadow: inset 0px 0px 0px 0px rgb(67 142 252);
  border: 2px solid #438efc;
}
.paycurrencysss__active svg{
  color: #438efc;
  font-size: 34px;
}